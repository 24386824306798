<template>
	<div class="list-tool-bar">
		<el-button-group v-if="!$userRole('SuperAdmin')" class="action">
      <!-- 创建 -->
			<el-button @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
		</el-button-group>
		<div class="search">
			<div class="search_item">
				<el-input
          v-model="searchData.search"
          @input.native="doSearch"
          :prefix-icon="Search"
          :placeholder="t('form.search_placeholder')"
          class="search-input"
					clearable />
			</div>
      <!-- 所属企业 -->
			<div v-if="$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])" class="search_item">
				<el-select
          v-model="searchData.filter_enterprise_id"
          filterable
          :placeholder="t('dashboard.enterprise')"
          @change="doSearch"
          clearable>
					<el-option
            v-for="item in enterpriseList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="search_item search_time">
				<div class="time_select margin-r2">
					<el-select v-model="timeType" @change="changeTimeType">
						<el-option :label="t('table.creation_time')" value="1" />
						<el-option :label="t('table.login_time')" value="2" />
					</el-select>
				</div>
				<!-- 时间选择框 -->
				<custem-time ref="timeRef" @setTime="setTime" />
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { enterpriseManagement } from '@/utils/api'

const t = inject('t')
const emit = defineEmits(['getList', 'onCreate'])
const timeType = ref('1') // 1: 创建时间  2: 登录时间
const searchData = ref({
  page: 1,
  filter_role_alias: 'enterprise'
})
const enterpriseList = ref([])
const timeRef = ref() // 时间组件ref

// 获取企业列表
const getEnterprise = async () => {
  const res = await enterpriseManagement.EnterpriseList({ is_paginate: false })
	enterpriseList.value = res.items
}

// 设置时间
const setTime = (timeDate) => {
  if (!timeDate.length) return
  searchData.value.filter_created_at = timeType.value === '1' ? timeDate[0] : ''
  searchData.value.filter_login_at = timeType.value === '1' ? '' : timeDate[0]
  searchData.value.filter_end_at = timeDate[1]
	doSearch()
}

// 时间类型下拉框change
const changeTimeType = () => {
  if (!Object.keys(searchData.value).length || !timeRef.value) return
  searchData.value = {}
  timeRef.value.clearTime()
  doSearch()
}

// 搜索事件
const doSearch = () => {
	for (const key in searchData.value) {
		if (!searchData.value[key]) delete searchData.value[key] // 删除没有值的参数，否则后端接口报错
	}
  searchData.value.page = 1
	emit('getList', searchData.value)
}

// 获取企业列表
onMounted(getEnterprise)
</script>
