<template>
  <div :class="{ main: route.fullPath === '/user/enterprise_user' }">
    <!-- 搜索栏 -->
    <ListToolBar v-if="route.fullPath === '/user/enterprise_user'" @getList="getList" @onCreate="winShow = true" />
    <custem-table ref="table" :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="pageChange"></custem-table>
    <!-- 新增/编辑表单 -->
    <!-- <form-data ref="formRef" :editData="editFormData" @getList="getList" /> -->
    <el-dialog
      v-model="winShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      @close="onClose"
      width="900px">
      <create-user
        ref="formRef"
        addType="Enterprise"
        :winShow="winShow"
        @getList="getList"
        @onClose="onClose" />
    </el-dialog>
    <!-- 详情 -->
    <Detail ref="detailRef" detailType="enterprise" />
  </div>
</template>

<script setup>

import ListToolBar from './components/ListToolBar'
import store from '@/store'
import Detail from '@/components/UserDetail/index.vue'
import { userManagement, LoginByID } from '@/utils/api'
import { onMounted, reactive, ref, getCurrentInstance, inject, computed } from 'vue'
import { ElMessageBox } from "element-plus"
import { useRoute } from 'vue-router'
import { nextTick } from 'vue'

const t = inject('t')
const table = ref()
const formRef = ref() // 表单对象
const detailRef = ref()
const { proxy } = getCurrentInstance()
const route = useRoute() // 路由对象
const editFormData = ref({})
const tableLoading = ref(false)
const edit_id = ref('')
const winShow = ref(false)
const searchData = ref({
  page: 1,
  filter_role_alias: 'enterprise'
})

// 编辑
const editData = async (row) => {
  const { id } = row
  if (!id) return
  winShow.value = true
  await nextTick()
  edit_id.value = id
  formRef.value.getUserDetail(edit_id.value)
}

const showDetail = async (data) => {
  await nextTick()
  detailRef.value.openWin(data)
}
const onClose = () => {
  if (formRef.value) formRef.value.clearForm()
  winShow.value = false
}
// 代管
const escrow = (data = '') => {
  ElMessageBox.confirm(
    `${t('user.sure_jump_before')}“${data.name}”${t('user.sure_jump_after')}`,
    t('user.jump_tip'),
    {
      confirmButtonText: t('form.submit'),
      cancelButtonText: t('form.cancel'),
      type: 'warning',
    }
  )
    .then(() => {
      LoginByID(data.id).then(res => {
        store.commit('CLEAR_ROUTER') // 清除路由
        store.commit('SET_ESCROW_ID', store.state.users.id) // 设置代管企业销售id
        store.commit('SET_USER', res) // 设置企业用户信息
      })
    }).catch()
}
// 弹框标题
const title = computed(() => {
  return !!edit_id.value ? `${t('table.modify')} ${t('role.role4')}` : `${t('table.create')} ${t('role.role4')}`
})
// 表格信息
const tableInfo = reactive({
  header: [
    { // 用户登录名
      label: t('user.username'),
      key: 'name',
      minWidth: '320px'
    },
    { // 所属企业
      key: 'enterprise.name',
      label: t('dashboard.enterprise'),
      minWidth: '320px',
      hidden: route.fullPath !== '/user/enterprise_user',
      custem: (data) => {
        if (data.enterprise)
          return data.enterprise.name
      }
    },
    { // 联系人姓名
      key: 'liaison_man',
      label: t('user.liaison_name'),
      minWidth: '120px',
      hidden: route.fullPath === '/user/enterprise_user',
      custem: (data) => {
        if (data.params)
          return data.params.liaison_man
        else return '-'
      }
    },
    { // 联系人邮箱
      key: 'liaison_mail',
      label: t('user.liaison_mail'),
      minWidth: '160px',
      hidden: route.fullPath === '/user/enterprise_user',
      custem: (data) => {
        if (data.params)
          return data.params.liaison_mail
        else return '-'
      }
    },
    { // 所属销售
      key: 'sales.name',
      label: t('user.sales'),
      minWidth: '320px',
      hidden: !!!proxy.$userRole(['GeneralAdmin', 'SuperAdmin']) && route.fullPath === '/user/enterprise_user',
      custem: (data) => {
        if (data.parent)
          return data.parent.name
      }
    },
    { // 创建时间
      key: 'created_at',
      label: t('table.creation_time'),
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      },
      minWidth: '180px'
    },
    {
      key: 'login_at',
      sortable: true,
      label: t('table.last_login_time'),
      sortBy: (data) => {
        return data.created_at
      },
      minWidth: '180px',
      hidden: route.fullPath !== '/user/enterprise_user'
    },
    {
      key: 'action',
      label: t('table.action'),
      width: proxy.$userRole(['GeneralAdmin', 'SuperAdmin']) ? '140px' : '220px',
      fixed: 'right',
      hidden: route.fullPath !== '/user/enterprise_user',
      handleFun: [
        {
          name: t('table.detail'),
          fn: showDetail
        },
        {
          name: t('table.modify'),
          fn: editData,
          hidden: () => {
            return !!!proxy.$userRole(['SalesMan', 'GeneralAdmin'])
          },
        },
        {
          name: t('table.custody'),
          fn: escrow,
          hidden: () => {
            return !!!proxy.$userRole(['SalesMan'])
          },
          types: 'primary',
          disabled: (data) => {
            return !data.params.tutelage
          }
        }
      ]
    }
  ]
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}
// 获取企业用户列表
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await userManagement.roleUserList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
defineExpose({
  getList: getList
})

onMounted(() => {
  if (route.fullPath === '/user/enterprise_user') getList()
})
</script>